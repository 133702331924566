import 'react-tabs/style/react-tabs.css'
import 'leaflet/dist/leaflet.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import L from 'leaflet'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import * as Icon from 'react-feather'
import ReactGA from 'react-ga'
import LazyLoad from 'react-lazyload'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import Fade from 'react-reveal/Fade'

export interface Props {
  address?: string
  email?: string
  image?: { [key: string]: any }
  latitude?: string
  longitude?: string
  phone?: string
  siteName?: string
  title?: string
  zoom?: string
}

export const Map = memo(function Map({
  address,
  email,
  image,
  latitude,
  longitude,
  phone,
  siteName,
  title,
  zoom,
}: Props) {
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: window.innerWidth > 1023 ? [60, 60] : [30, 30],
        iconAnchor: window.innerWidth > 1023 ? [30, 60] : [15, 30],
        html: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719 c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/></svg>',
      }),
    )
  }, [])

  return (
    <Container>
      <Wrapper row>
        {image ? (
          <Background>
            <LazyLoad>
              <Image {...image} />
            </LazyLoad>
          </Background>
        ) : null}
        <Text>
          {title ? (
            <Fade bottom>
              <Title>{title}</Title>
            </Fade>
          ) : null}
          <Fade bottom>
            <>
              {address ? (
                <Address>
                  <Icon.MapPin />
                  {address}
                </Address>
              ) : null}
              {phone ? (
                <ContactsItem>
                  <Phone
                    href={`tel:${phone}`}
                    onClick={() => {
                      if (isMobile) {
                        ReactGA.event({
                          category: 'Website',
                          action: 'Click',
                          label: 'Mobile Phone',
                        })
                      }
                    }}
                  >
                    <Icon.Phone />
                    {phone}
                  </Phone>
                </ContactsItem>
              ) : null}
              {email ? (
                <ContactsItem>
                  <Email
                    href={`mailto:${email}`}
                    onClick={() => {
                      ReactGA.event({
                        category: 'Website',
                        action: 'Click',
                        label: 'Email address',
                      })
                    }}
                  >
                    <Icon.Mail />
                    {email}
                  </Email>
                </ContactsItem>
              ) : null}
            </>
          </Fade>
        </Text>
      </Wrapper>
      <MapBody>
        <LazyLoad>
          <Image {...image} />
        </LazyLoad>
        <MapWrapper>
          {typeof window !== undefined && latitude && longitude ? (
            <LazyLoad>
              <MapContainer
                center={[Number(latitude), Number(longitude)]}
                dragging={L.Browser && L.Browser.mobile ? false : true}
                zoom={Number(zoom)}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                />
                <Marker
                  icon={markerIcon}
                  position={[Number(latitude), Number(longitude)]}
                >
                  {siteName ? <Popup>{siteName}</Popup> : null}
                </Marker>
              </MapContainer>
            </LazyLoad>
          ) : null}
        </MapWrapper>
      </MapBody>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem auto 15.75rem;
  padding: 6rem 0;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 4.5rem;
    left: 4.5rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto 11.25rem;
    padding: 3.75rem 0;
    &:before {
      right: 1.5rem;
      left: 1.5rem;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 6rem;
    padding: 0;
    &:before {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)``

const Background = styled.div`
  width: 40%;
  padding-bottom: 48%;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Text = styled.div`
  margin-left: 6.458vw;
  position: relative;
  z-index: 4;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    padding: 0 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 2.9375rem;
`

const ContactsItemStyle = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.variants.neutralDark4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin-bottom: 1.625rem;
  &:last-of-type {
    margin-top: 0;
  }
  svg {
    width: 1.25rem;
    height: auto;
    stroke: ${theme.colors.variants.primaryDark1};
    stroke-width: 1.5;
    margin-right: 1rem;
  }
`

const ContactsItem = styled.div``

const Address = styled.div`
  ${ContactsItemStyle}
`

const Phone = styled.a`
  ${ContactsItemStyle}
  &:hover {
    text-decoration: underline;
  }
`

const Email = styled.a`
  ${ContactsItemStyle}
  &:hover {
    text-decoration: underline;
  }
`

const MapBody = styled.div`
  width: calc(100% - 12.639vw);
  height: 100%;
  position: absolute;
  top: 6rem;
  left: 0;
  .lazyload-wrapper {
    img {
      width: calc(40% - 5vw);
      height: calc(100% - 10vw - 12rem);
      object-fit: cover;
      position: absolute;
      top: 5vw;
      left: 5vw;
      z-index: 3;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 50vh;
    margin-top: 1.5rem;
    position: relative;
    top: auto;

    .lazyload-wrapper {
      display: none;
    }
  }
`

const MapWrapper = styled.div`
  width: 68%;
  padding-bottom: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    .leaflet-left {
      display: none;
    }
  }

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .leaflet-marker-icon {
    background: none;
    border: 0;
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-weight: 100;
        font-size: 2.375rem;
        border: 0;
        border-radius: 0;
        transition: 0.3s ease-out;
        &.leaflet-control-zoom-in {
          order: 2;
        }
        &.leaflet-disabled {
          opacity: 0.6;
          pointer-events: none;
        }
        &:hover {
          background: ${({ theme }) => theme.colors.variants.neutralLight3};
        }
      }
    }
  }
  .leaflet-bottom {
    display: none;
  }
  .leaflet-popup {
    .leaflet-popup-content-wrapper {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      box-shadow: none;
      border-radius: 0;
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1rem;
      padding: 1rem;
    }
    .leaflet-popup-content {
      line-height: 1.5rem;
      margin: 0;
    }
    .leaflet-popup-tip {
      background: ${({ theme }) => theme.colors.variants.primaryDark1};
      box-shadow: none;
    }
    .leaflet-popup-close-button {
      display: none;
    }
  }
`
